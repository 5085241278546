<template>
  <div class="d-flex flex-column justify-content-center align-items-center">
    <b-img
      v-show="img !== null && imageValue === null"
      :src="img !== null ? url + img : ''"
      alt="Uploaded Image"
      rounded
      height="220"
      class="w-auto"
    />
    <b-img
      v-show="imageValue !== null"
      ref="refPreviewEl"
      :src="JSON.stringify(imageValue)"
      alt="Preview Image"
      rounded
      height="220"
      class="w-auto"
    />
    <span v-show="img === null && imageValue === null">No Photos Uploaded</span>
    <b-button
      v-if="isImageEditable"
      variant="gradient-primary"
      class="font-small-2 my-2 fixed-width-button"
      pill
      @click="$refs.refInputEl.$el.click()"
    >
      UPLOAD
    </b-button>
    <b-form-file
      ref="refInputEl"
      v-model="imageValue"
      accept=".jpg, .png, .jpeg"
      :hidden="true"
      plain
      @input="renderImage()"
    />
  </div>
</template>

<script>
import { useInputImageRenderer } from "@core/comp-functions/forms/form-utils";
import { ref } from "@vue/composition-api";

export default {
  props: {
    isImageEditable: {
      type: Boolean,
      required: true,
    },

    label: {
      type: String,
      required: true,
    },

    baseURL: {
      type: String,
      required: true,
    },

    img: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      url: this.baseURL,
      imageValue: null,
    };
  },

  mounted() {
    // console.log(this.img);
  },

  setup() {
    const refInputEl = ref(null);
    const refPreviewEl = ref(null);

    const { inputImageRenderer } = useInputImageRenderer(
      refInputEl,
      (base64) => {
        refPreviewEl.value.src = base64;
      }
    );

    return {
      refInputEl,
      refPreviewEl,
      inputImageRenderer,
    };
  },

  methods: {
    renderImage() {
      this.inputImageRenderer();
      this.$emit("renderImage", this.imageValue, this.label);
    },
  },
};
</script>

<style scoped>
.fixed-width-button {
  width: 150px;
}
</style>
